/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Web3Context } from '../contexts';
import { H2, A, P, Stack, PrimaryButton, Button } from './';
import BigNumber from 'bignumber.js';
import { web3Util } from '../utils/web3Util';
import Web3 from 'web3';
import REQUEST_URL from '../data/REQUEST_URL';
import styled from 'styled-components';
import { Input } from './Input';
import { NUM_TOKENS } from '../data';
import { strokeWidth } from '../style';

const mints = [1, 5, 10, 20];

const DonationInput = styled(Input)`
  width: calc(100% - 2em - ${2 * strokeWidth}px);
  max-width: none;
`;

const ErrorMsg = styled(P)`
  color: red;
  font-size: 13px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
  margin-top: 2em;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
`;

export default function Mint() {
  const context = React.useContext(Web3Context);
  const [remainingAmount, updateAmount] = React.useState<string | null>(null);

  const enable = () => {
    try {
      web3Util.enable();
    } catch {}
  };
  React.useEffect(() => {
    if (window.localStorage?.getItem(web3Util.LS_KEY) === 'true') {
      enable();
    }
    const fn = async () => {
      try {
        const { totalSupply } = await fetch(
          `${REQUEST_URL}/safepassage/total-supply`,
        ).then(res => res.json());
        updateAmount(new BigNumber(NUM_TOKENS).minus(totalSupply).toString());
      } catch {
        // error
      }
    };
    fn();
  }, []);

  const mint = async (amount: number) => {
    // @ts-ignore
    const contract = web3Util.getMintingContract();
    const total = new BigNumber(amount).times(0.06);

    if (contract) {
      try {
        await contract.methods.mint(amount).send({
          from: web3Util.accounts?.[0],
          value: Web3.utils.toWei(total.toString()),
        });
      } catch (e: any) {
        try {
          alert(JSON.stringify(e.message));
        } catch {
          // no op
        }
      }
    }
  };

  const remaining = (
    <P>
      {remainingAmount === null
        ? '...'
        : remainingAmount == '0'
        ? 'Sold out'
        : `${Number(remainingAmount).toLocaleString()} of
      ${NUM_TOKENS.toLocaleString()} tokens remaining`}
    </P>
  );

  return !context.hasProvider ? (
    <>
      <H2>Connect wallet to purchase</H2>
      {remaining}
      <P>
        Please{' '}
        <A
          highlight
          href="https://metamask.io"
          target="_blank"
          rel="noreferrer"
        >
          install Metamask
        </A>{' '}
        to mint Safe Passage NFTs.
      </P>
    </>
  ) : !context.address ? (
    <>
      <H2>Connect wallet to purchase</H2>
      <PrimaryButton onClick={enable}>Connect wallet</PrimaryButton>
    </>
  ) : (
    <>
      <H2>Purchase now</H2>
      {remaining}
      <Row>
        <Stack>
          {mints.map((amount, i) => (
            <PrimaryButton onClick={() => mint(amount)} key={i}>
              {`Mint ${amount} - ${new BigNumber(0.06)
                .times(amount)
                .toString()} ETH`}
            </PrimaryButton>
          ))}
        </Stack>
      </Row>
    </>
  );
}
