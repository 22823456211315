import styled from 'styled-components';
import { lineHeight, inputWidth, strokeWidth } from '../style';

export type InputProps = {
  error?: boolean;
};

export const Input = styled.input.attrs({
  autoComplete: 'off',
  spellCheck: false,
})`
  color: var(--text-color);
  border: 0;
  margin: 0;
  height: 1em;
  padding: 1em;
  -webkit-appearance: none;
  box-sizing: content-box;
  border-radius: 0;
  width: ${inputWidth}px;
  display: block;
  position: relative;

  &[type='checkbox'],
  &[type='radio'] {
    width: ${1.5 * lineHeight}px;
    display: inline-block;
  }
  &[type='radio'] {
    border-radius: ${0.75 * lineHeight}px;
  }

  &[type='text'] {
    border: ${strokeWidth}px solid
      ${({ error }: InputProps) =>
        error ? 'var(--highlight-color)' : 'var(--text-color)'};
    padding: ${1 * lineHeight}px ${0.5 * lineHeight}px;
  }

  &:checked:after {
    content: '';
    position: absolute;
    top: ${lineHeight / 2}px;
    left: ${lineHeight / 2}px;
    width: ${lineHeight / 2}px;
    height: ${lineHeight / 2}px;
    background: var(--input-text-color);
  }
  &[type='radio']:checked:after {
    border-radius: ${lineHeight / 4}px;
  }
`;
