import styled from 'styled-components';

export const H1 = styled.h1`
  font-weight: 600;
  font-size: 4em;
  line-height: 1.25em;
  letter-spacing: 0.1em;
  color: var(--text-color);
  user-select: text;
  text-transform: uppercase;
`;
