import { useState, useEffect } from 'react';
import REQUEST_URL from '../data/REQUEST_URL';

export const useAPIData = <Data>(
  path?: string | null,
  key: any[] = [],
): [boolean, string | null, Data | null, (data: Data) => void] => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Data | null>(null);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      setLoading(true);
      setData(null);
      setError(null);
      if (path) {
        const response = await fetch(`${REQUEST_URL}/${path}`);
        if (response.status === 200) {
          setData(await response.json());
        } else {
          const { error } = await response.json();
          setError(error);
        }
        setLoading(false);
      }
    })();
  }, key);
  return [loading, error, data, setData];
};
