import React, { FC } from 'react';
import { A, ContentLayout, P } from '../../components';

export const FAQ: FC = () => {
  return (
    <ContentLayout
      title="FAQ"
      path="/faq"
      sections={[
        {
          id: 'faq',
          title: 'FAQ',
          content: <></>,
        },
        {
          id: 'who-needs-to-be-rescued',
          title: 'Who needs to be rescued?',
          content: (
            <>
              <P>
                Afghans that advocated for human rights (including women’s
                rights and LGBTQ rights), women leaders, journalists, aid
                workers, the LGBTI+ community, those who supported our troops,
                worked for US government programs or the former Afghan
                government, all face imminent danger under Taliban rule.
              </P>
              <P>
                Unfortunately, the US government failed to plan for the ongoing
                support of at-risk Afghans. The rapid fall of the Afghan
                government and ensuing insecurity collapsed any existing NGO
                capacity to provide services for this vulnerable population. As
                a result, veterans and volunteers have responded.
              </P>
              <P>
                Providing humanitarian support and safe passage for Afghan
                refugees is highly complex, requiring robust planning,
                logistics, and resources.
              </P>
              <P>
                <strong>
                  There is currently no funding allocated to humanitarian
                  support in Afghanistan, where conditions change and become
                  more complex every minute.
                </strong>
              </P>
            </>
          ),
        },
        {
          id: 'where-do-the-funds-go',
          title: 'Where do the funds go?',
          content: (
            <>
              <P>
                SOAA is an organization composed of American volunteers from the
                Special Operations community – their team, alongside an
                operational rescue partner of experts, who have managed crises
                and evacuations in unstable conditions, are working to bring
                home our Afghan brothers and sisters.
              </P>
              <P>
                Our Afghanistan mission is to safely provide humanitarian
                support on the ground in alignment with relevant US policies.
              </P>
              <P>
                Our funds do not support the Taliban or other hostile forces.
              </P>
            </>
          ),
        },
        {
          id: 'why-support-soaa',
          title: 'Why support SOAA vs others?',
          content: (
            <>
              <P>
                SOAA is actively providing CRITICAL support to humanitarian
                operations in Afghanistan. Due to the lack of funding at the
                federal level, SOAA adds unmatched flexibility for US
                humanitarian operations by legally and safely coordinating
                logistics on the ground in Afghanistan. SOAA is the ESSENTIAL
                STOPGAP to continue to deliver humanitarian support until policy
                catches up with the ground truth.
              </P>
            </>
          ),
        },

        {
          id: 'how-will-the-money-be-used',
          title: 'How will the money be used?',
          content: (
            <>
              <P>
                Funding will support two main areas: life support and transport.
              </P>
              <P>
                Life Support: lodging, food, security, and medical assistance
                for evacuees while in transit in-country and awaiting refugee
                and asylum case adjudication in onward destinations.
              </P>
              <P>
                Transport: Transport to safe locations by locally contracted
                operators with trusted, vetted partners.
              </P>
            </>
          ),
        },
        {
          id: 'how-is-the-money-handled',
          title: 'How is the money handled?',
          content: (
            <>
              <P>
                The NFT contract sends ETH directly to SOAA’s wallet upon
                minting. SOAA is a veteran non-profit organized under 501(c)(19)
                and takes custody of all funds in accordance with its bylaws.
              </P>
            </>
          ),
        },
        {
          id: 'why-raise-funds-with-an-nft',
          title: 'Why raise funds with an NFT?',
          content: (
            <>
              <P>
                This project gives the crypto community a chance to come
                together and show that crypto is a force for good in the world.
              </P>
              <P>
                NFTs and the crypto community have an incredible ability to
                raise funds for emergency relief in situations where time is of
                the essence.
              </P>
              <P>
                This is a historic humanitarian crisis that needs the support of
                people worldwide, standing up for those seeking a more promising
                future and often the ability to merely survive.
              </P>
              <P>
                The timelessness of the Safe Passage NFT will be a permanent
                reminder that those who purchased a Safe Passage NFT stood up
                for another individual's life, helping with the financial need
                it takes to get them and their family to safety.
              </P>
            </>
          ),
        },
        {
          id: 'will-there-be-progress-updates',
          title: 'Will there be progress updates?',
          content: (
            <>
              <P>
                SOAA will continue to update progress on our website at{' '}
                <A href="https://www.SOAA.org" target="_blank">
                  https://www.SOAA.org
                </A>
                .
              </P>
              <P>
                We will also be providing updates in the{' '}
                <A href="https://t.me/safepassageNFT" target="_blank">
                  Safe Passage Telegram
                </A>{' '}
                channel.
              </P>
            </>
          ),
        },
        {
          id: 'tax-deductible',
          title: 'Is the purchase price of the NFT tax deductible?',
          content: (
            <>
              <P>
                Probably not, because you are receiving the NFT in exchange for
                your payment. But we can't provide individual tax advice. Please
                consult with your tax advisor.
              </P>
            </>
          ),
        },
        {
          id: 'can-i-donate-to-soaa',
          title: 'Can I donate directly to SOAA?',
          content: (
            <>
              <P>
                Yes. You can donate through{' '}
                <A
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.soaa.org/"
                >
                  SOAA's website
                </A>
                .
              </P>
            </>
          ),
        },
        {
          id: 'how-does-the-nft-work',
          title: 'How does the NFT work?',
          content: (
            <>
              <P>
                Each Safe Passage NFT is generated at time of mint. The
                technical details were inspired by projects like Art Blocks and
                Loot. The art is generated using a seed made up from different
                unique parameters that exist during the creation of the NFT. The
                script to generate the image can be retrieved at any time by
                using a standard method within the smart contract to retrieve
                the metadata for a given NFT.
              </P>
              <P>
                Both the script to generate the image and the JSON which
                contains the metadata are generated on-chain and returned as
                Base64 data URLs. That means these NFTs do not rely on a third
                party hosting service to serve the data, or images associated
                with them. They will last for as long as Ethereum does.
              </P>
            </>
          ),
        },
        {
          id: 'what-does-it-mean-to-be-on-chain',
          title: 'What does “On-Chain” mean?',
          content: (
            <>
              <P>
                As the artwork of the NFT is programmatically generated, it will
                always live on the blockchain, whereas most image NFTs are just
                references to off-chain storage. We host the images for each
                Safe Passage NFT as a convenience method for NFT marketplaces,
                but all data can be pulled directly from the NFT metadata which
                is created in the smart contract.
              </P>
            </>
          ),
        },
      ]}
    />
  );
};
