import React, { FC, ReactNode, useContext } from 'react';
import styled from 'styled-components';
import { Toolbar } from './Toolbar';
import { ToolbarGroup } from './ToolbarGroup';
import { maxTextContentWidth } from '../style';
import { WalletLink } from './WalletLink';
import { PageTitle } from './PageTitle';
import { Airplane } from './Airplane';
import { Afghanistan } from './Afghanistan';
import { Link } from './Link';
import { Web3Context } from '../contexts';

export type LayoutProps = {
  title: string;
  children?: ReactNode;
  breadcrumbs?: ReactNode;
  fullWidth?: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100vw;
  overflow: hidden;
`;

const Breadcrumbs = styled.div`
  display: flex;
  white-space: pre;
  min-width: 240px;
`;

const Icons = styled.span`
  padding-right: 0.33em;
`;

type ContentProps = {
  fullWidth?: boolean;
};
const Content = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  padding: 0 1em 3em;
  width: 100%;
  max-width: ${({ fullWidth }: ContentProps) =>
    fullWidth ? 'none' : `${maxTextContentWidth}px`};
`;

export const Layout: FC<LayoutProps> = ({
  title,
  children,
  breadcrumbs,
  fullWidth,
}) => {
  const { active } = useContext(Web3Context);
  return (
    <>
      <PageTitle title={title} />
      <Container>
        <Toolbar as="header">
          <ToolbarGroup>
            <Breadcrumbs>
              <Link plain href="/">
                <Icons>
                  <Airplane />
                  <Afghanistan />
                </Icons>
                Safe Passage
              </Link>
              {breadcrumbs}
            </Breadcrumbs>
          </ToolbarGroup>
          <ToolbarGroup right>{active && <WalletLink />}</ToolbarGroup>
        </Toolbar>
        <Content fullWidth={fullWidth}>{children}</Content>
      </Container>
    </>
  );
};
