import React, { FC } from 'react';
import {
  Layout,
  A,
  Link,
  NetworkActivityIndicator,
  Center,
  Art,
  Stack,
} from '../../components';
import { NUM_TOKENS } from '../../data';
import SAFE_PASSAGE_CONTRACT_ADDRESS from '../../data/SAFE_PASSAGE_CONTRACT_ADDRESS';
import { useAPIData } from '../../hooks';
import { Metadata } from '../../types';

export type GalleryItemProps = {
  path: string[];
};

export const GalleryItem: FC<GalleryItemProps> = ({ path }) => {
  const tokenId = parseInt(path[1]);
  let [loading, error, data] = useAPIData<Metadata>(
    `safepassage/metadata/${tokenId}`,
    [tokenId],
  );

  return (
    <Layout
      title="Safe Passage - Gallery"
      breadcrumbs={
        <>
          {' / '}
          <Link plain href={`/gallery`}>
            Gallery
          </Link>
          {' / '}
          <Link plain href={`/gallery/${tokenId}`}>
            #{tokenId}
          </Link>
        </>
      }
    >
      {data ? (
        <>
          <Art seed={parseInt(data.seed)} />
          <Stack leading>
            <div>
              Safe Passage #{tokenId} of {NUM_TOKENS.toLocaleString()}
            </div>
            {tokenId < NUM_TOKENS && (
              <div>
                <Link href={`/gallery/${tokenId + 1}`}>Next</Link>
              </div>
            )}
            {tokenId > 1 && (
              <div>
                <Link href={`/gallery/${tokenId - 1}`}>Previous</Link>
              </div>
            )}
            <div>
              <A
                href={`https://opensea.io/assets/${SAFE_PASSAGE_CONTRACT_ADDRESS}/${tokenId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View on OpenSea
              </A>
            </div>
          </Stack>
        </>
      ) : (
        <Center>
          <NetworkActivityIndicator
            pending={false}
            active={loading}
            error={!!error}
          />
        </Center>
      )}
    </Layout>
  );
};
