import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import {
  H1,
  H2,
  P,
  A,
  Link,
  GridLayout,
  Footer,
  Layout,
  Art,
} from '../../components';
import Mint from '../../components/Mint';
import { Web3Context } from '../../contexts';

export type HomeProps = {};

const Section = styled.section`
  margin-bottom: 3em;
`;

export const Home: FC<HomeProps> = () => {
  const { active, activeLoading } = useContext(Web3Context);
  return (
    <Layout title="Safe Passage">
      <Section>
        <H1>SAFE PASSAGE</H1>
        <P>
          Each Safe Passage NFT supports the ongoing evacuation of thousands of
          vulnerable people from Afghanistan.
        </P>
        <P>
          Safe Passage means finding the most optimal, timely path for providing
          individuals and families refuge for immediate and urgent shelter,
          safety, and life support, while transportation and resettlement
          options are being planned for evacuation to safer ground.
        </P>
        <P>
          The 11,111 unique, on-chain generative artworks represent the
          thousands of routes around the world refugees take for Safe Passage.
          The path to safety is never static. Modes of movement constantly
          evolve when their lives are in danger under hostile conditions.
        </P>
      </Section>

      <Section>
        {activeLoading && <P>Please wait...</P>}
        {!activeLoading && (
          <>
            {!active && (
              <P>
                <strong>
                  Available here on Wednesday, September 22 at 8am PST for 0.06
                  ETH
                </strong>
              </P>
            )}
            {active && <Mint />}
          </>
        )}

        <Art seed={4.309974477278472e76} />
      </Section>

      <Section>
        <H2>Urgent need</H2>
        <P>
          Thousands of Afghans who helped transform Afghanistan into a place of
          freedom, democracy, and empowerment over the last 20 years have been
          left behind. In a matter of days, they watched their country fall, and
          the life they knew changed instantly.
        </P>
        <P>
          Their future is now at risk, facing a life of repression,
          imprisonment, torture, and death.
        </P>
        <P>
          Special Operations Association of America (SOAA -{' '}
          <A href="https://www.soaa.org" target="_blank">
            www.soaa.org
          </A>
          ) needs your IMMEDIATE help to provide shelter, safety, and
          humanitarian support.
        </P>
        <P>
          <Link href="/faq">Learn more</Link>
        </P>
      </Section>
      <Section>
        <H2>Safe passage gallery</H2>
        <GridLayout minSize={150}>
          {[1, 2, 3].map(i => (
            <Art key={i} seed={i} sw={0.003} />
          ))}
        </GridLayout>
        <P>
          Browse the gallery to explore unique variations of the Safe Passage
          artwork.
        </P>
        <P>
          <Link href="/gallery">View Gallery</Link>
        </P>
      </Section>
      <Section>
        <H2>Who Built This?</H2>
        <P>
          This on-chain NFT project was built by{' '}
          <Link target="_blank" href="https://twitter.com/realnftboi">
            nftboi
          </Link>{' '}
          and{' '}
          <Link target="_blank" href="https://twitter.com/tr666eth">
            tr666
          </Link>
          , creators of{' '}
          <Link
            href="https://opensea.io/collection/pixelglyphs"
            target="_blank"
          >
            Pixelglyphs
          </Link>{' '}
          and{' '}
          <Link
            href="https://opensea.io/collection/audioglyphs"
            target="_blank"
          >
            Audioglyphs
          </Link>
          . nftboi has been a builder in the NFT space since 2017 building
          across various projects and contributing to NFT standards on Ethereum.
          They have worked closely with legal experts and SOAA to ensure the
          legitimacy of this project. This was all done on a volunteer basis in
          the hopes of saving lives. All proceeds go directly from the smart
          contract to SOAA's Ethereum wallet. More information can be found in{' '}
          <Link target="_blank" href="https://soaa.org/safe-passage-nft/">
            this blog post on SOAA's website
          </Link>
          .
        </P>
      </Section>
      <Section>
        <H2>More</H2>
        <Footer />
      </Section>
    </Layout>
  );
};
