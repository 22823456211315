import styled from 'styled-components';
import { maxTextContentWidth } from '../style';

export const H2 = styled.h2`
  font-weight: 600;
  font-size: 2em;
  line-height: 1.25em;
  letter-spacing: 0.1em;
  color: var(--text-color);
  text-transform: uppercase;
  max-width: ${maxTextContentWidth}px;
  user-select: text;
`;
