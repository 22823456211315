import styled from 'styled-components';
import { inputWidth, strokeWidth } from '../style';

export const Button = styled.button`
  display: block;
  width: ${inputWidth}px;
  color: var(--text-color);
  border: ${strokeWidth}px solid var(--text-color);
  background: var(--background-color);
  padding: 1em;
  text-align: left;
  hyphens: auto;
  overflow-wrap: anywhere;
  position: relative;
  cursor: pointer;

  &:hover {
    color: var(--highlight-color-alt);
    border-color: var(--highlight-color-alt);
  }
`;

export const PrimaryButton = styled(Button)`
  border: ${strokeWidth}px solid var(--text-color);
  padding: 1em;

  color: var(--text-color);
  background: white;

  &:hover {
    color: var(--highlight-color-alt);
  }
`;
