import React, { FC } from 'react';
import styled from 'styled-components';
import { Art } from './Art';
import { Link } from './Link';

const Container = styled(Link)`
  &:hover {
    p {
      color: var(--highlight-color-alt);
    }
  }
`;

export type GalleryLinkProps = {
  seed: number;
  tokenId: number;
};

export const GalleryLink: FC<GalleryLinkProps> = ({ seed, tokenId }) => (
  <Container plain key={seed} href={`/gallery/${tokenId}`}>
    <Art seed={seed} sw={0.003} />
    <p style={{ textAlign: 'center' }}>#{tokenId}</p>
  </Container>
);
