import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from './components';

const link = document.querySelector('link[rel=icon]');
link?.setAttribute(
  'href',
  `data:image/svg+xml,${encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1">
      <rect x="0" y="0" width="1" height="1" fill="#fff" />
      <circle cx="0.5" cy="0.5" r="0.4" fill="none" stroke-width="0.08" stroke="#000"/>
    </svg>`,
  )}`,
);

// render app
ReactDOM.render(<Root />, document.getElementById('app'));
