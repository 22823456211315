import React, { FC, useContext } from 'react';
import { GridLayout } from './GridLayout';
import { Link } from './Link';
import styled from 'styled-components';
import SAFE_PASSAGE_CONTRACT_ADDRESS from '../data/SAFE_PASSAGE_CONTRACT_ADDRESS';
import { Web3Context } from '../contexts';

const FooterLink = styled(Link)`
  font-size: 1.25em;
`;

export const Footer: FC = () => {
  const { active } = useContext(Web3Context);

  const links: any[][] = [
    ['/faq', 'FAQ'],
    ...(active ? [['/gallery', 'Gallery']] : []),
    [
      'https://twitter.com/SOAAorg',
      'Twitter',
      { target: '_blank', rel: 'noopener noreferrer' },
    ],
    [
      'https://t.me/safepassageNFT',
      'Telegram',
      { target: '_blank', rel: 'noopener noreferrer' },
    ],

    [
      `https://etherscan.io/address/${SAFE_PASSAGE_CONTRACT_ADDRESS}`,
      'Smart contract',
      { target: '_blank', rel: 'noopener noreferrer' },
    ],

    ['/terms', 'Terms'],
  ];
  return (
    <GridLayout minSize={150}>
      {links.map(([href, text, props = {}], i) => (
        <div>
          <FooterLink key={i} href={href} {...props}>
            {text}
          </FooterLink>
        </div>
      ))}
    </GridLayout>
  );
};
