import React from 'react';
import { web3Util } from '../utils';
import { Web3Context } from '../contexts';
import REQUEST_URL from '../data/REQUEST_URL';

function Web3Provider({ children }: { children: React.ReactNode }) {
  const [accounts, setAccounts] = React.useState<string[] | undefined>();
  const [active, updateIsActive] = React.useState(true);
  const [activeLoading, updateActiveLoading] = React.useState(false);
  React.useEffect(() => {
    web3Util.on('accountsUpdated', newAccounts => {
      setAccounts(newAccounts);
    });
    if (window.localStorage?.getItem(web3Util.LS_KEY) === 'true') {
      try {
        web3Util.enable();
      } catch {
        // no op
      }
    }
  }, []);

  return (
    <Web3Context.Provider
      value={{
        address: (accounts && accounts[0]) ?? null,
        hasProvider: web3Util.hasProvider,
        active,
        activeLoading,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
}

export default Web3Provider;
