import React, { FC } from 'react';
import styled from 'styled-components';
import {
  GridLayout,
  Layout,
  Link,
  NetworkActivityIndicator,
  Stack,
  Center,
  GalleryLink,
} from '../../components';
import { useAPIData } from '../../hooks';
import { Metadata } from '../../types';

export type GalleryProps = {
  path: string[];
};

const Grid = styled(GridLayout)`
  margin: 4em 0;
`;

export const Gallery: FC<GalleryProps> = ({ path }) => {
  const param = parseInt(path[0].split(/\?page=/)[1]);
  const page = Number.isNaN(param) ? 1 : param;
  let [loading, error, data] = useAPIData<{
    count: number;
    numberOfPages: number;
    results: Metadata[];
  }>(`safepassage/gallery?page=${page}`, [page]);

  return (
    <Layout
      title="Safe Passage - Gallery"
      breadcrumbs={
        <>
          {' / '}
          <Link plain href={`/gallery`}>
            Gallery
          </Link>
        </>
      }
      fullWidth
    >
      {data ? (
        <>
          <Grid minSize={250} space={3}>
            {data.results?.map(({ seed, tokenId }) => {
              return (
                <GalleryLink
                  key={seed}
                  seed={parseInt(seed)}
                  tokenId={parseInt(tokenId)}
                />
              );
            })}
          </Grid>
          <Stack>
            <div>
              Page {page} of {data?.numberOfPages ?? '--'}
            </div>
            {page > 1 && (
              <div>
                <Link href={`/gallery?page=${page - 1}`}>Previous</Link>
              </div>
            )}
            {data?.numberOfPages && page < data.numberOfPages && (
              <div>
                <Link href={`/gallery?page=${page + 1}`}>Next</Link>
              </div>
            )}
          </Stack>
        </>
      ) : (
        <Center>
          <NetworkActivityIndicator
            pending={false}
            active={loading}
            error={!!error}
          />
        </Center>
      )}
    </Layout>
  );
};
