import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Stack } from './Stack';
import { FormLinks } from './FormLinks';
import { Link } from './Link';
import { maxTextContentWidth } from '../style';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const Col = styled.div`
  max-width: ${maxTextContentWidth}px;
  padding: 1em;
  box-sizing: content-box;
`;

export type BasicLayoutProps = {
  children: ReactNode;
};

export const BasicLayout: FC<BasicLayoutProps> = ({ children }) => {
  return (
    <Container>
      <Col>
        <Stack>{children}</Stack>
        <FormLinks>
          <Link href="/">home</Link>
        </FormLinks>
      </Col>
    </Container>
  );
};
