import React, { FC, useContext } from 'react';
import {
  GridLayout,
  Link,
  GalleryLink,
  P,
  H2,
  Center,
  NetworkActivityIndicator,
  Layout,
} from '../../components';
import { Wallet as WalletModel } from '../../types';
import { useAPIData } from '../../hooks';
import { shortAddress } from '../../utils';
import { Web3Context } from '../../contexts';

export type WalletProps = {
  path: string[];
};

export const Wallet: FC<WalletProps> = ({ path: [, address] }) => {
  const { address: ownAddress } = useContext(Web3Context);

  const [loading, error, data] = useAPIData<WalletModel>(
    `safepassage/wallet/${address}`,
  );

  const isOwner = ownAddress === address;

  return (
    <Layout
      title={`Safe Passage - wallet ${address}`}
      breadcrumbs={
        <>
          {' / '}
          <Link plain href={`/wallet/${address}`}>
            {shortAddress(address)}
          </Link>
        </>
      }
      fullWidth
    >
      {data ? (
        <>
          <div style={{ flex: 1 }}>
            <H2>{isOwner ? 'Your collection' : `Collection`}</H2>
            {data.results.length === 0 ? (
              isOwner ? (
                <>
                  <P>You don't have anything here yet</P>
                  <P>
                    <Link highlight href="/">
                      Mint now
                    </Link>
                  </P>
                </>
              ) : (
                <P>None yet</P>
              )
            ) : (
              <GridLayout minSize={240}>
                {data.results.map(({ seed, tokenId }) => (
                  <GalleryLink
                    key={seed}
                    seed={parseInt(seed)}
                    tokenId={parseInt(tokenId)}
                  />
                ))}
              </GridLayout>
            )}
          </div>
        </>
      ) : (
        <Center>
          <NetworkActivityIndicator
            pending={false}
            active={loading}
            error={!!error}
          />
        </Center>
      )}
    </Layout>
  );
};
