import styled from 'styled-components';

export type ToolbarGroupProps = {
  right?: boolean;
};

export const ToolbarGroup = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  height: 3em;
  padding: 0 1em;
  overflow: hidden;
  justify-content: ${({ right }: ToolbarGroupProps) =>
    right ? 'flex-end' : 'flex-start'};
  > *:nth-child(n + 2) {
    margin-left: 0.5em;
  }
`;
