import styled from 'styled-components';

export type GridLayoutProps = {
  minSize: number;
  space?: number;
};

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ minSize }: GridLayoutProps) => minSize}px, 1fr)
  );
  gap: ${({ space = 1.5 }: GridLayoutProps) => space}em;
`;
