import React, { FC } from 'react';
import { GlobalStyle } from './GlobalStyle';
import { Router } from './Router';
import { Home, FAQ, Gallery, GalleryItem, Terms, Wallet } from '../pages';
import { NotFoundPage } from './NotFoundPage';
import Web3Provider from './Web3Provider';

export type RootProps = {};

export const Root: FC<RootProps> = () => {
  return (
    <>
      <GlobalStyle />
      <Web3Provider>
        <Router
          routes={[
            [/^\/$/, Home],
            [/^\/faq$/, FAQ],
            [/^\/terms$/, Terms],
            [/^\/gallery(\?page=\d+)?$/, Gallery],
            [/^\/gallery\/\d{1,6}$/, GalleryItem],
            [/^\/wallet\/0[xX][0-9a-fA-F]+$/, Wallet],
          ]}
          NotFound={() => <NotFoundPage />}
        />
      </Web3Provider>
    </>
  );
};
