import styled from 'styled-components';

export const Toolbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
`;
