import { createGlobalStyle } from 'styled-components';
import { fontSize, fontWeight, lineHeight, strokeWidth } from '../style';

export const GlobalStyle = createGlobalStyle`
  html, body, #app {
    margin: 0;
    padding: 0;
    user-select: none;
    display: block;
    font-weight: ${fontWeight};
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
    color: var(--text-color);
  }

  svg {
    fill: currentColor;
  }

  body {
    --background-color: #fff;
    --text-color: #000;
    --highlight-color: #e31;
    --highlight-color-alt: #0008ff;
    --button-hover-color: #000;
    --button-hover-text-color: #fff;
  }

  * {
    box-sizing: border-box;
    font-family: "Azeret", monospace;
    vertical-align: top;
  }

  *:focus {
    z-index: 1;
    outline: solid var(--highlight-color-alt) ${strokeWidth}px;
    outline-offset: 0.25em;
  }

  ::selection {
    color: var(--background-color);
    background: var(--highlight-color-alt);
  }
`;
