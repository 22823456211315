import React, { FC } from 'react';

export type ArtProps = {
  seed: number;
  mgn?: number;
  sw?: number;
};

const { PI, cos, sin, round } = Math;
const im = 2147483647;
const rand = (s: number) => (mn = 0, mx = 1) => (
  (s = (s * 16807) % im), mn + ((mx - mn) * s) / im
);

export const Art: FC<ArtProps> = ({ seed, mgn = 0.05, sw = 0.002 }) => {
  const r = rand(Number(seed));

  const rim = r(0.01, 0.04);

  const rad = 0.5 - mgn - rim;
  const p1 = r(5, 25);
  const p2 = r(5, 25);

  const cyc = round(r(10, 60));
  const wbl = r(0, 0.15) * mgn;

  const sda = `${r(0.01, 0.1)} ${r(0, 0.1)}`;

  const _1 = `stroke-width=${sw} stroke-dasharray="${sda}" stroke="#000" fill="none"/>`;
  const _2 = `<circle cx=0.5 cy=0.5 r=`;
  const _3 = `<polyline points="`;

  const __html = `
<svg width="100%" height="100%" viewBox="0 0 1 1" preserveAspectRatio="xMidYMid meet">
<defs>
<clipPath id="c${seed}">
${_2}${0.5 - mgn - rim} />
</clipPath>
</defs>
${_3}${new Array(361)
    .fill(0)
    .map((_, i) => {
      const rd = (i / 180) * PI;
      const rrd = 0.5 - mgn + sin(rd * cyc) * wbl;
      return `${0.5 + rrd * sin(rd)} ${0.5 + rrd * cos(rd)}`;
    })
    .join(',')}"
${_1}
${_2}${0.5 - mgn - rim}
${_1}
${_3}${new Array(2000)
    .fill(0)
    .map((_, i) => `${cos(i / p1) * rad + 0.5} ${cos(i / p2) * rad + 0.5}`)
    .join(',')}"
clip-path="url(#c${seed})"
${_1}
</svg>
`;

  return (
    <div style={{ margin: '0 -5% 0' }} dangerouslySetInnerHTML={{ __html }} />
  );
};
