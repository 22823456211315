import React, { FC, useContext } from 'react';
import { A } from './A';
import { Link } from './Link';
import { web3Util, shortAddress } from '../utils';
import { Web3Context } from '../contexts';

export const WalletLink: FC = () => {
  const { address, hasProvider } = useContext(Web3Context);

  return address !== null ? (
    <Link href={`/wallet/${address}`}>Wallet {shortAddress(address)}</Link>
  ) : hasProvider ? (
    <A
      onClick={() => {
        try {
          web3Util.enable();
        } catch {}
      }}
    >
      Connect wallet
    </A>
  ) : null;
};
