import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { H1 } from './H1';
import { H2 } from './H2';
import { Layout } from './Layout';
import { Link } from './Link';
import { Footer } from './Footer';
import { Section } from './Section';

const HeaderLink = styled.a`
  text-decoration: none;
`;

export type ContentLayoutProps = {
  title: string;
  path: string;
  sections: {
    id: string;
    title: string;
    content: ReactNode;
  }[];
};

export const ContentLayout: FC<ContentLayoutProps> = ({
  title,
  path,
  sections,
}) => {
  return (
    <Layout
      title={`Safe Passage - ${title}`}
      breadcrumbs={
        <>
          {' / '}
          <Link plain href={path}>
            {title}
          </Link>
        </>
      }
    >
      {sections.map(({ id, title, content }, i) => (
        <Section style={{ margin: 0 }} id={id}>
          <HeaderLink href={`#${id}`}>
            {i === 0 ? <H1>{title}</H1> : <H2>{title}</H2>}
          </HeaderLink>
          {content}
        </Section>
      ))}
      <Section style={{ marginTop: '4em' }}>
        <H2 style={{ marginTop: 0 }}>More</H2>
        <Footer />
      </Section>
    </Layout>
  );
};
